import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import ImageCardGrid from "components/cards/ImageCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import HotelLandingImageSrc from "images/hotel/landing.jpg";

import SeaRoom1 from "images/hotel/room-sea/cakraz-umut-otel-fotograflari-6.jpg";
import SeaRoom2 from "images/hotel/room-sea/cakraz-umut-otel-fotograflari-7.jpg";
import SeaRoom3 from "images/hotel/room-sea/cakraz-umut-otel-fotograflari-8.jpg";
import SeaRoom4 from "images/hotel/room-sea/cakraz-umut-otel-fotograflari-9.jpg";

import SeaWithoutRoom1 from "images/hotel/room-without-sea/cakraz-umut-otel-fotograflari-10.jpg";
import SeaWithoutRoom2 from "images/hotel/room-without-sea/cakraz-umut-otel-fotograflari-11.jpg";

import FamilySuitRoom1 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-12.jpg";
import FamilySuitRoom2 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-13.jpg";
import FamilySuitRoom3 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-14.jpg";
import FamilySuitRoom4 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-15.jpg";
import FamilySuitRoom5 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-16.jpg";
import FamilySuitRoom6 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-17.jpg";
import FamilySuitRoom7 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-18.jpg";
import FamilySuitRoom8 from "images/hotel/family-suit/cakraz-umut-otel-fotograflari-19.jpg";

import Faq from "images/hotel/faq.jpg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-2xl`;

  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            Çakraz'ın Kalbinde
            <HighlightedText>Konfor ve Huzur</HighlightedText>
          </>
        }
        description="Umut Otel, doğanın içinde huzurlu bir konaklama deneyimi sunar. Denize sadece birkaç adım mesafede olan otelimiz, Çakraz'ın eşsiz plajları ve doğal güzellikleriyle çevrilidir. Rahat odalarımız, lezzetli yemekler sunan restoranımız ve sıcak atmosferimizle misafirlerimize unutulmaz bir tatil vaat ediyoruz."
        imageSrc={HotelLandingImageSrc}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Hemen Arayın"
        primaryButtonUrl="tel:+903783356494"
        secondaryButtonText="Odalarımızı Görüntüleyin"
        secondaryButtonUrl="#odalarımız"
      />
      {/*       <MainFeature
        subheading={<Subheading>Established Since 2014</Subheading>}
        heading={
          <>
            We've been serving for
            <wbr /> <HighlightedText>over 5 years.</HighlightedText>
          </>
        }
        description={
          <Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            <br />
            <br />
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={
          "https://images.unsplash.com/photo-1460306855393-0410f61241c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      /> */}
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}

      <div id="odalarımız">
        <ImageCardGrid
          heading={<>Odalarımız</>}
          tabs={{
            "Deniz Manzaralı": [
              {
                imageSrc: SeaRoom1,
              },
              {
                imageSrc: SeaRoom2,
              },
              {
                imageSrc: SeaRoom3,
              },
              {
                imageSrc: SeaRoom4,
              },
            ],
            "Deniz Görmeyen": [
              {
                imageSrc: SeaWithoutRoom1,
              },
              {
                imageSrc: SeaWithoutRoom2,
              },
            ],
            "Aile Suit (2 Odalı)": [
              {
                imageSrc: FamilySuitRoom1,
              },
              {
                imageSrc: FamilySuitRoom2,
              },
              {
                imageSrc: FamilySuitRoom3,
              },
              {
                imageSrc: FamilySuitRoom4,
              },
              {
                imageSrc: FamilySuitRoom5,
              },
              {
                imageSrc: FamilySuitRoom6,
              },
              {
                imageSrc: FamilySuitRoom7,
              },
              {
                imageSrc: FamilySuitRoom8,
              },
            ],
          }}
        />
      </div>

      <Features
        heading={
          <>
            Neden <HighlightedText>Bizi seçmelisiniz?</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Mükemmel Konum",
            description:
              "Umut Otel, Cakraz'ın en güzel plajlarına ve doğal güzelliklerine sadece birkaç adım mesafede. Deniz ve doğanın tadını çıkarırken huzurlu bir konaklama deneyimi yaşayın.",
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Lezzetli Yemekler",
            description:
              "Otelimizin restoranında, deneyimli şeflerimizin hazırladığı lezzetli yemeklerin tadını çıkarın. Yerel mutfaktan seçkin lezzetlerle tatilinizi unutulmaz kılın.",
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Misafirperverlik",
            description:
              "Misafir memnuniyeti bizim önceliğimizdir. Güler yüzlü ve profesyonel ekibimiz, konaklamanız süresince tüm ihtiyaçlarınızı karşılamak için hizmetinizde.",
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      {/* <Testimonial
        subheading=""
        heading={
          <>
            Customers <HighlightedText>Love Us.</HighlightedText>
          </>
        }
      /> */}
      <FAQ
        heading="SSS"
        description="İşte siz değerli müşterilerimizin otelimiz hakkında sıkça sorduğu bazı sorular. Başka sorularınız varsa, bizi arayabilirsiniz."
        imageSrc={Faq}
        faqs={[
          {
            question: "Oda özellikleri nelerdir?",
            answer:
              "Otelimizin odalarında duş, eşya dolabı, fön makinesi, havlu, Wi-Fi (internet), oda temizlik hizmeti, telefon, televizyon, uydu yayını, çalışma masası, çamaşır makinesi ve buzdolabı bulunmaktadır.",
          },
          {
            question: "Otelde otopark var mı?",
            answer: "Hayır, otelimizde otopark bulunmamaktadır.",
          },
          {
            question: "Otelde havuz var mı?",
            answer: "Hayır, otelimizde havuz bulunmamaktadır.",
          },
          {
            question: "Umut Otel'in fiyatları ve ücretleri nasıl öğrenilir?",
            answer:
              "En uygun konaklama fiyatlarımız için lütfen bizimle iletişime geçin.",
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
